<template>
  <div class="curriculum-index">
    <banner-bg :type="4">
      <div class="curriculum-index-base">
        <new-banner :bannerList="bannerList" :height="600"></new-banner>
      </div>
    </banner-bg>
    <div class="curriculum-index-body">
      <div class="curriculum-index-title">
        <span class="title">热门课程</span>
        <span>最受大家欢迎的热门课程</span>
        <!-- <div class="curriculum-btn" v-if="titleConfig.isInit">
          <div class="curriculum-block">
            <el-switch
              @change="changeCourseList(2)"
              :disabled="titleConfig.serviceLevel == 1 ? true : false"
              v-model="platform"
              active-color="#5C9DF8"
            >
            </el-switch>
            <span
              :class="
                platform == true ? 'curriculum-span-light' : 'curriculum-span'
              "
              >平台课程</span
            >
          </div>
          <div class="curriculum-block">
            <el-switch
              @change="changeCourseList(1)"
              v-model="queryParam.selfBuild"
              :disabled="titleConfig.serviceLevel == 1 ? true : false"
              active-color="#5C9DF8"
            >
            </el-switch>
            <span
              :class="
                queryParam.selfBuild == true
                  ? 'curriculum-span-light'
                  : 'curriculum-span'
              "
              >学校课程</span
            >
          </div>
        </div> -->
        <div class="switch-tab" v-if="titleConfig.isInit">
          <div
            class="tab-item"
            :class="platform ? 'tab-item-active' : ''"
            @click="
              () => {
                if(platform) return
                platform = !platform;
                changeCourseList(2);
              }
            "
          >
            平台课程
          </div>
          <div
            class="tab-item"
            :class="queryParam.selfBuild ? 'tab-item-active' : ''"
            @click="
              () => {
                if(queryParam.selfBuild) return
                queryParam.selfBuild = !queryParam.selfBuild;
                changeCourseList(1);
              }
            "
          >
            学校课程
          </div>
          <div
            class="tab-background-bar"
            :class="
              platform ? 'tab-background-bar-left' : 'tab-background-bar-right'
            "
          ></div>
        </div>
        <div class="clear"></div>
      </div>
      <template v-if="showTag == true">
        <CourseTag
          :type="'courseType'"
          :datas="courseTypes"
          :activeParam="queryParam.tid"
          @changeTags="handleQueryChange"
        />
        <CourseTag
          :type="'grade'"
          :datas="gradeTags"
          :activeParam="queryParam.grade"
          @changeTags="handleQueryChange"
        />
        <CourseTag
          :type="'difficulty'"
          :datas="difficultyTags"
          :activeParam="queryParam.difficulty"
          @changeTags="handleQueryChange"
        />
      </template>

      <div class="tag-info">
          <div class="tool-bar">
            <div class="search-box">
              <el-input
                class="box-input"
                placeholder="请输入课程名称"
                clearable
                size="small"
                v-model="searchValue"
                @change="searchValueList"
              />
              <el-button
                class="box-button box-button-search"
                @click="searchValueList"
                type="primary"
                >搜索</el-button
              >
              <el-button
                class="box-button box-button-reset"
                @click="resetSearchValue"
                type="info"
                >重置</el-button
              >
            </div>
            <!-- <div class="model-box" :class="{ 'model-box-active': modelValue }">
              <div class="box-desc">
                {{ modelValue ? '精简模式' : '列表模式' }}
              </div>
              <el-switch
                @change="switchModel"
                class="box-button"
                active-color="#5C9DF8"
                v-model="modelValue"
              />
            </div> -->
            <SwitchTab
              :tabValue="$store.state.curriculumModelValue"
              :tabList="[
                { value: true, label: '精简模式' },
                { value: false, label: '列表模式' },
              ]"
              @tabClick="switchModel"
            />
          </div>
          <img
            v-if="queryParam.cover && !$store.state.curriculumModelValue"
            :src="queryParam.cover"
          />
        </div>
      <template
        v-if="
          queryParam.selfBuild == true &&
          (curriculums.length == 0 || showTag == false)
        "
      >
        <div class="my-tip">您当前学校没有学校课程，请选择使用平台课程</div>
      </template>
      <template v-else>
        <div class="tag-info">
          <!-- <Curriculums
            :queryParam="queryParam"
            :datas="curriculums"
            :show-other="true"
          ></Curriculums> -->
          <IndexCard
            key="card"
            v-if="!$store.state.curriculumModelValue"
            :queryParam="queryParam"
            :datas="curriculums"
            :show-other="true"
          ></IndexCard>
          <IndexList
            key="list"
            v-else
            :queryParam="queryParam"
            :datas="curriculums"
            :show-other="true"
          ></IndexList>
          <div class="table-pagination">
            <el-pagination
              background
              layout="sizes,prev, pager, next, jumper"
              :total="queryParam.total"
              :current-page="queryParam.pageNo"
              :page-size="queryParam.pageSize"
              :page-sizes="
                $store.state.curriculumModelValue
                  ? [10, 15, 20]
                  : [3, 5, 10, 20]
              "
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getBanners } from '@/api/common';
import { mapState } from 'vuex';

import Curriculums from '@/components/Curriculums';
import CourseTag from '@/components/CourseTag';
import NewBanner from '@/components/NewBanner.vue';

import { courseTags, courses, getCourseList } from '@/api/course';
import BannerBg from '@/components/BannerBg.vue';

import IndexCard from './components/IndexCard.vue';
import IndexList from './components/IndexList.vue';

import SwitchTab from '@/components/SwitchTab.vue';
const defaultQuerys = {
  pageNo: 1,
  pageSize: 10,
  total: 0,
  tid: null,
  grade: '全部',
  difficulty: '全部',
  cover: null,
};
export default {
  name: 'Curriculum-index',
  components: {
    Curriculums,
    BannerBg,
    CourseTag,
    NewBanner,
    IndexCard,
    IndexList,
    SwitchTab,
  },
  data() {
    return {
      platform: true,
      showTag: true,
      courseTypes: [],
      difficultyTags: [],
      gradeTags: [],
      curriculums: [],
      bannerList: [],
      searchValue: '',
    };
  },
  computed: {
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
      queryParam: (state) => state.courseQueryParams,
    }),
  },
  mounted() {
    this.getTags();
  },
  created() {
    this.platform = !this.queryParam.selfBuild;
    this.getBannerList();
  },
  methods: {
    changeCourseList(type) {
      if (type == 1) {
        this.platform = !this.queryParam.selfBuild;
      } else {
        this.queryParam.selfBuild = !this.platform;
      }
      this.courseTypes = [];
      this.difficultyTags = [];
      this.gradeTags = [];
      this.curriculums = [];
      let newQueryParam = Object.assign({}, defaultQuerys);
      newQueryParam.selfBuild = this.queryParam.selfBuild;
      this.$store.dispatch('SetCourseQuery', newQueryParam);
      this.getTags();
    },
    getTags() {
      let param = {};
      if (this.queryParam.selfBuild == true) {
        param = { selfBuild: true };
      }
      courseTags(param).then((res) => {
        if (res.success) {
          // this.courseTypes = res.result.courseTypes ?? [];
          this.courseTypes = [{name:'全部课程',id:''},...res.result.courseTypes] ?? [];
          this.gradeTags = res.result.gradeTags ?? [];
          this.difficultyTags = res.result.difficultyTags ?? [];
          if (this.courseTypes.length > 0) {
            this.showTag = true;
            if (this.queryParam.tid == null) {
              this.queryParam.tid = this.courseTypes[0].id;
            }
            if (this.queryParam.cover == null) {
              this.queryParam.cover = this.courseTypes[0].cover;
            }
          } else {
            this.showTag = false;
          }
          this.getCurriculum();
        }
      });
    },
    handleQueryChange(item) {
      if (item.type == 'courseType') {
        this.queryParam.tid = item.id;
        this.queryParam.cover = item.cover;
      } else if (item.type == 'grade') {
        this.queryParam.grade = item.id;
      } else {
        this.queryParam.difficulty = item.id;
      }
      this.getCurriculum();
    },
    getCurriculum() {
      this.queryParam.courseName = this.searchValue;
      courses(this.queryParam).then((res) => {
        if (res.success) {
          this.queryParam.pageNo = res.result.current;
          this.queryParam.total = res.result.total;
          this.curriculums = res.result.records.map((item) => {
            return {
              id: item.id,
              avator: item.author.avator,
              courseCover: item.courseCover,
              userlink: item.author.link,
              name: item.courseName,
              ke: item.hour,
              author: item.author.name,
              userId: item.author.userId || '1',
              star: item.star,
              up: item.up,
              views: item.view,
              tags: item.tags,
              // tags: (item.tags ? item.tags.split(',') : []).map(item => {
              //   return this.allTags[item]
              // }),
              difficulty: item.difficulty,
              grade: (item.grade ?? '').split('/')[0],
              typeName: item.typeName,
              typeId: item.typeId,
              desc: item.courseDesc,
              isStar: false,
              isCollect: false,
            };
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.queryParam.pageNo = val;
      this.getCurriculum();
    },
    handleSizeChange(val) {
      this.queryParam.pageSize = val;
      this.getCurriculum();
    },
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'kc',
        platform: 'yqb',
        isPortal: '1',
      };
      getBanners(param).then((res) => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    toSystem() {
      this.$router.push({ path: `/curriculumsystem` });
    },
    resetSearchValue() {
      this.searchValue = '';
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = 10;
      this.getCurriculum();
    },
    switchModel(value) {
      this.$store.commit('SET_CURRICULUM_MODEL_VALUE', value);
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = 10;
      this.getCurriculum();
    },
    searchValueList() {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = 10;
      this.getCurriculum();
    },
  },
};
</script>

<style lang="scss" scoped>
.curriculum-index {
  width: 100%;
  min-width: 1200px;
  .curriculum-index-base {
    width: 100%;
    height: 600px;
    position: relative;
    margin: auto;
    max-width: 1920px;
    .link {
      color: #fff;
      background-color: #f59a23;
      font-size: 13px;
      text-align: center;
      display: inline-block;
      width: 184px;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      position: absolute;
      z-index: 999;
      top: 40px;
      right: 50px;
    }
    .img {
      position: relative;
      width: 1000px;
      height: 100%;
      margin: 0 auto;
      padding-top: 30px;
      img {
        position: absolute;
        top: 0;
        width: 100%;
      }
      p.title {
        font-size: 36px;
        color: #fff;
        font-weight: bold;
        padding-left: 45px;
        line-height: 1;
      }
      p.desc {
        font-size: 20px;
        color: #fff;
        margin-top: 10px;
        padding-left: 45px;
        line-height: 1;
      }
    }
  }
  .curriculum-index-body {
    width: 1200px;
    margin: 0 auto;
    .curriculum-index-title {
      border-bottom: 1px solid #eff3f8;
      padding: 5px 0;
      margin-top: 60px;
      span {
        font-size: 16px;
        color: #9dafc8;
        vertical-align: bottom;
        line-height: 1;
        &.title {
          font-size: 30px;
          color: #333;
          margin-right: 30px;
          font-weight: bold;
        }
      }
      .switch-tab {
        float: right;
        width: 120px;
        height: 30px;
        background-color: #eff3f8;
        position: relative;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        .tab-item {
          position: relative;
          z-index: 999;
          width: 60px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #5c9df8;
        }
        .tab-item-active {
          color: #fff;
        }
        .tab-background-bar {
          position: absolute;
          width: 60px;
          height: 28px;
          background-color: #5c9df8;
          top: 1px;
          transition: all 0.3s;
          left: 0;
        }
        .tab-background-bar-left {
          left: 0;
        }
        .tab-background-bar-right {
          left: 60px;
        }
      }
    }
    ul.tags {
      margin-top: 10px;
      display: flex;
      & > li {
        vertical-align: top;
      }
      .tag-item {
        background-color: #eff3f8;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #4a596f;
        font-size: 14px;
        border-radius: 15px;
        padding: 0 15px;
        margin-right: 20px;
        margin-bottom: 15px;
        cursor: pointer;
        &:hover {
          color: #0081ef;
        }
        &.active {
          color: #ffffff;
          background-image: linear-gradient(
            -90deg,
            #85d2ff 0%,
            #c78aff 98.43924191750108%
          );
          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .tag-info {
      position: relative;
      .link {
        color: #fff;
        background-color: #f59a23;
        font-size: 13px;
        text-align: center;
        display: inline-block;
        width: 184px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        position: absolute;
        z-index: 999;
        top: 10px;
        right: 20px;
      }
      & > img {
        width: 1200px;
        height: 480px;
        margin-bottom: 30px;
        border-radius: 10px;
      }
      .tool-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0 50px;
        .search-box {
          display: flex;
          align-items: center;
          .box-input::v-deep {
            .el-input__inner {
              font-size: 12px;
              color: #333;
              width: 200px;
              height: 30px;
              border: 1px solid #999;
              border-radius: 5px;
              &::placeholder {
                color: #999;
              }
              &:focus {
                border: 1px solid #5c9df8;
                &::placeholder {
                  color: #333;
                }
              }
            }
          }
          .box-button {
            width: 50px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            margin-left: 10px;
            border: none;
            border-radius: 5px;
          }
          .box-button-search {
            background-color: #5c9df8;
            &:hover {
              background-color: #96c2ff;
            }
          }
          .box-button-reset {
            background-color: #999999;
            &:hover {
              background-color: #c9c9c9;
            }
          }
        }
        .model-box {
          display: flex;
          align-items: center;
          .box-desc {
            font-size: 14px;
            color: #999;
            margin-right: 5px;
          }
        }
        .model-box-active {
          .box-desc {
            color: #5c9df8;
          }
        }
      }
    }
  }
}
.my-tip {
  margin: auto;
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 24px;
  line-height: 1;
  margin-bottom: 50px;
}
.curriculum-btn {
  float: right;
  margin-bottom: 10px;
  .curriculum-block {
    display: inline-block;
    padding: 1px 5px 3px 5px;
    margin-left: 15px;
    border-radius: 5px;
    .curriculum-span {
      color: #d5d5d5 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      margin-left: 7px;
    }
    .curriculum-span-light {
      color: #5c9df8 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      margin-left: 7px;
    }
  }
}
.clear {
  clear: both;
}
</style>
<style lang="scss">
.el-tooltip__popper {
  background: rgba(48, 49, 51, 0.5) !important;
  .popper__arrow {
    border-top-color: rgba(48, 49, 51, 0.2) !important;
    &::after {
      border-top-color: rgba(48, 49, 51, 0.3) !important;
    }
  }
}

.curriculum-tooltip {
  max-width: 500px !important;
}
</style>
