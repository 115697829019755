var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum-index"},[_c('banner-bg',{attrs:{"type":4}},[_c('div',{staticClass:"curriculum-index-base"},[_c('new-banner',{attrs:{"bannerList":_vm.bannerList,"height":600}})],1)]),_c('div',{staticClass:"curriculum-index-body"},[_c('div',{staticClass:"curriculum-index-title"},[_c('span',{staticClass:"title"},[_vm._v("热门课程")]),_c('span',[_vm._v("最受大家欢迎的热门课程")]),(_vm.titleConfig.isInit)?_c('div',{staticClass:"switch-tab"},[_c('div',{staticClass:"tab-item",class:_vm.platform ? 'tab-item-active' : '',on:{"click":function () {
              if(_vm.platform) { return }
              _vm.platform = !_vm.platform;
              _vm.changeCourseList(2);
            }}},[_vm._v(" 平台课程 ")]),_c('div',{staticClass:"tab-item",class:_vm.queryParam.selfBuild ? 'tab-item-active' : '',on:{"click":function () {
              if(_vm.queryParam.selfBuild) { return }
              _vm.queryParam.selfBuild = !_vm.queryParam.selfBuild;
              _vm.changeCourseList(1);
            }}},[_vm._v(" 学校课程 ")]),_c('div',{staticClass:"tab-background-bar",class:_vm.platform ? 'tab-background-bar-left' : 'tab-background-bar-right'})]):_vm._e(),_c('div',{staticClass:"clear"})]),(_vm.showTag == true)?[_c('CourseTag',{attrs:{"type":'courseType',"datas":_vm.courseTypes,"activeParam":_vm.queryParam.tid},on:{"changeTags":_vm.handleQueryChange}}),_c('CourseTag',{attrs:{"type":'grade',"datas":_vm.gradeTags,"activeParam":_vm.queryParam.grade},on:{"changeTags":_vm.handleQueryChange}}),_c('CourseTag',{attrs:{"type":'difficulty',"datas":_vm.difficultyTags,"activeParam":_vm.queryParam.difficulty},on:{"changeTags":_vm.handleQueryChange}})]:_vm._e(),_c('div',{staticClass:"tag-info"},[_c('div',{staticClass:"tool-bar"},[_c('div',{staticClass:"search-box"},[_c('el-input',{staticClass:"box-input",attrs:{"placeholder":"请输入课程名称","clearable":"","size":"small"},on:{"change":_vm.searchValueList},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('el-button',{staticClass:"box-button box-button-search",attrs:{"type":"primary"},on:{"click":_vm.searchValueList}},[_vm._v("搜索")]),_c('el-button',{staticClass:"box-button box-button-reset",attrs:{"type":"info"},on:{"click":_vm.resetSearchValue}},[_vm._v("重置")])],1),_c('SwitchTab',{attrs:{"tabValue":_vm.$store.state.curriculumModelValue,"tabList":[
              { value: true, label: '精简模式' },
              { value: false, label: '列表模式' } ]},on:{"tabClick":_vm.switchModel}})],1),(_vm.queryParam.cover && !_vm.$store.state.curriculumModelValue)?_c('img',{attrs:{"src":_vm.queryParam.cover}}):_vm._e()]),(
        _vm.queryParam.selfBuild == true &&
        (_vm.curriculums.length == 0 || _vm.showTag == false)
      )?[_c('div',{staticClass:"my-tip"},[_vm._v("您当前学校没有学校课程，请选择使用平台课程")])]:[_c('div',{staticClass:"tag-info"},[(!_vm.$store.state.curriculumModelValue)?_c('IndexCard',{key:"card",attrs:{"queryParam":_vm.queryParam,"datas":_vm.curriculums,"show-other":true}}):_c('IndexList',{key:"list",attrs:{"queryParam":_vm.queryParam,"datas":_vm.curriculums,"show-other":true}}),_c('div',{staticClass:"table-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"sizes,prev, pager, next, jumper","total":_vm.queryParam.total,"current-page":_vm.queryParam.pageNo,"page-size":_vm.queryParam.pageSize,"page-sizes":_vm.$store.state.curriculumModelValue
                ? [10, 15, 20]
                : [3, 5, 10, 20]},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }