<template>
  <ul class="tags">
    <template v-for="(item, index) in datas">
      <li
        v-if="index == 0"
        style="min-width: 130px;"
        :key="index"
        @click="changeTags(item)"
        :class="['tag-item',{active: activeParam == item.id}]"
      >{{item.name}}</li>
    </template>
    <ul>
      <template v-for="(item, index) in datas">
        <li
          v-if="index != 0"
          :key="index"
          @click="changeTags(item)"
          :class="['tag-item',{active: activeParam == item.id}]"
        >{{item.name}}</li>
      </template>
    </ul>
  </ul>
</template>
<script>
export default {
  name: 'courseTag',
  props: {
    datas: {},
    activeParam: null,
    type: null
  },
  methods: {
    changeTags(item) {
      item.type = this.type;
      this.$emit('changeTags', item);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/curriculum-index.scss';
</style>