var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-list"},[(!_vm.datas || _vm.datas.length == 0)?_c('span',{staticClass:"tip-text"},[_vm._v("更多课程开放，请联系管理员")]):_c('ul',{staticClass:"index-list-list"},_vm._l((_vm.datas),function(item,key){return _c('li',{key:item.id,staticClass:"list-item",style:({ 'margin-right': (key + 1) % 5 === 0 ? '0px' : '25px' })},[_c('img',{directives:[{name:"real-img",rawName:"v-real-img",value:(item.courseCover),expression:"item.courseCover"}],staticClass:"item-img",attrs:{"src":require("@/assets/image/curriculum/c-bg.svg")}}),_c('div',{staticClass:"item-main"},[_c('div',{staticClass:"main-title"},[_c('el-tooltip',{attrs:{"enterable":false,"placement":"top","content":item.name}},[_c('div',{staticClass:"title-content",on:{"click":function($event){return _vm.goCurriculum(item.id)}}},[_vm._v(_vm._s(item.name)+" ")])]),_c('div',{staticClass:"title-num"},[_vm._v(_vm._s(item.ke)+"课时")])],1),(item.desc)?_c('el-tooltip',{attrs:{"enterable":false,"placement":"top","content":item.desc,"popper-class":"curriculum-tooltip"}},[_c('div',{staticClass:"main-desc"},[_vm._v(_vm._s(item.desc))])]):_c('div',{staticClass:"main-desc"},[_vm._v(_vm._s(item.desc))]),_c('el-tooltip',{attrs:{"enterable":false,"placement":"top","content":((item.tags
            .map(function (item) { return ("[" + (item.typeName) + "] "); })
            .join('')) + "[" + (item.grade) + "] [" + (item.difficulty) + "]")}},[_c('ul',{staticClass:"main-tags"},[_vm._l((item.tags),function(tag){return [(tag.typeName)?_c('li',{key:tag.typeId,staticClass:"tags-item"},[_vm._v(" "+_vm._s(tag.typeName)+" ")]):_vm._e()]}),(item.grade)?_c('li',{staticClass:"tags-item"},[_vm._v(_vm._s(item.grade))]):_vm._e(),(item.difficulty)?_c('li',{staticClass:"tags-item"},[_vm._v(_vm._s(item.difficulty))]):_vm._e()],2)]),_c('div',{staticClass:"main-buttons"},[_c('div',{staticClass:"buttons-item",on:{"click":function($event){return _vm.goCurriculum(item.id)}}},[_vm._v(" 立即学习 ")]),_c('div',{staticClass:"buttons-item",on:{"click":function($event){return _vm.collectOrStar(item, 2)}}},[_vm._v(" 加入收藏 ")])]),_c('div',{staticClass:"main-handle"},[_c('a',{staticClass:"handle-link",attrs:{"href":item.userlink}},[_c('el-avatar',{staticClass:"link-avatar",attrs:{"icon":"el-icon-user-solid","src":item.avator}}),_c('div',{staticClass:"link-name"},[_vm._v(_vm._s(item.author))])],1),_c('div',{staticClass:"handle-content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-icon",class:[
                  'iconfont',
                  item.isCollect
                    ? 'iconyishoucang0081ef active'
                    : 'iconshoucang979EB1',
                  'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 2)}}}),_c('div',{staticClass:"item-num"},[_vm._v(_vm._s(item.up || 0))])]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-icon",class:[
                  'iconfont icondianzan979EB1',
                  item.isStar
                    ? 'iconyidianzan0081ef active'
                    : 'icondianzan979EB1',
                  'can-active' ],on:{"click":function($event){return _vm.collectOrStar(item, 1)}}}),_c('div',{staticClass:"item-num"},[_vm._v(_vm._s(item.star || 0))])]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-icon iconfont iconguankancishu"}),_c('div',{staticClass:"item-num"},[_vm._v(_vm._s(item.views))])])])])],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }