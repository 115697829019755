<template>
  <div class="index-card">
    <span v-if="!datas || datas.length == 0" class="tip-text"
      >更多课程开放，请联系管理员</span
    >
    <ul v-else class="index-card-list">
      <li class="list-item" v-for="item in datas" :key="item.id">
        <img
          class="item-img"
          src="~@/assets/image/curriculum/c-bg.svg"
          v-real-img="item.courseCover+'#svgView(preserveAspectRatio(none))'"
        />
        <div class="item-main">
          <div class="main-title">
            <el-tooltip :enterable="false" placement="top" :content="item.name">
            <div class="title-content" @click="goCurriculum(item.id)">{{ item.name }}
            </div>
            </el-tooltip>
            <div class="title-num">{{ item.ke }}课时</div>
          </div>
          <el-tooltip
          :enterable="false"
          placement="top"
            v-if="item.desc"
            :content="item.desc"
            popper-class="curriculum-tooltip"
          >
            <div class="main-desc">{{ item.desc }}</div></el-tooltip
          >
          <div v-else class="main-desc">{{ item.desc }}</div>
          <el-tooltip
              placement="top" :enterable="false"
            :content="`${item.tags
              .map((item) => `[${item.typeName}]`)
              .join()} [${item.grade}] [${item.difficulty}]`"
          >
          <ul class="main-tags">
            <template v-for="tag in item.tags">
              <li class="tags-item" :key="tag.typeId" v-if="tag.typeName">
                {{ tag.typeName }}
              </li>
            </template>
            <li class="tags-item" v-if="item.grade">{{ item.grade }}</li>
            <li class="tags-item" v-if="item.difficulty">{{ item.difficulty }}</li>
          </ul>
          </el-tooltip>
          <div class="main-buttons">
            <div class="buttons-item" @click="goCurriculum(item.id)">
              立即学习
            </div>
            <div class="buttons-item" @click="collectOrStar(item, 2)">
              加入收藏
            </div>
          </div>
          <div class="main-handle">
            <a class="handle-link" :href="item.userlink">
              <el-avatar
                class="link-avatar"
                icon="el-icon-user-solid"
                :src="item.avator"
              ></el-avatar>
              <div class="link-name">{{ item.author }}</div>
            </a>
            <div class="handle-content">
              <div class="content-item">
                <div
                  class="item-icon"
                  :class="[
                    'iconfont',
                    item.isCollect
                      ? 'iconyishoucang0081ef active'
                      : 'iconshoucang979EB1',
                    'can-active',
                  ]"
                  @click="collectOrStar(item, 2)"
                ></div>
                <div class="item-num">{{ item.up || 0 }}</div>
              </div>
              <div class="content-item">
                <div
                  class="item-icon"
                  :class="[
                    'iconfont icondianzan979EB1',
                    item.isStar
                      ? 'iconyidianzan0081ef active'
                      : 'icondianzan979EB1',
                    'can-active',
                  ]"
                  @click="collectOrStar(item, 1)"
                ></div>
                <div class="item-num">{{ item.star || 0 }}</div>
              </div>
              <div class="content-item">
                <div class="item-icon iconfont iconguankancishu"></div>
                <div class="item-num">{{ item.views }}</div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { putLikes } from '@/api/community';
import { getMyConcerns } from '@/api/user';
import Vue from 'vue';
export default {
  props: {
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
    showOther: {
      type: Boolean,
      default() {
        return false;
      },
    },
    queryParam: {},
  },
  data() {
    return {};
  },
  watch: {
    datas(value) {
      if (Vue.ls.get('Access-Token')) {
        this.getStarAndCollect(value.map((item) => item.id));
      }
    },
  },
  methods: {
    getStarAndCollect(idList) {
      getMyConcerns({ workIds: idList.join(','), type: 'COURSE' }).then(
        (res) => {
          if (res.success) {
            this.datas.forEach((item) => {
              if (res.result[item.id] == 1) {
                item.isStar = true;
              } else if (res.result[item.id] == 2) {
                item.isCollect = true;
              } else if (res.result[item.id] == 3) {
                item.isCollect = true;
                item.isStar = true;
              }
            });
          }
        },
      );
    },
    goCurriculum(id) {
      this.$store.dispatch('SetCourseQuery', this.queryParam);
      this.$router.push({ path: '/curriculum-info', query: { cid: id } });
    },
    collectOrStar(item, opeType) {
      putLikes(
        { id: item.id, opeType: opeType, type: 'COURSE' },
        opeType == 2 ? item.isCollect : item.isStar,
      ).then((res) => {
        if (res.success) {
          this.$notify({
            title: '系统提示',
            message:
              opeType == 2
                ? item.isCollect
                  ? '取消收藏成功'
                  : '收藏成功'
                : item.isStar
                ? '取消点赞成功'
                : '点赞成功',
            type: 'success',
          });
          if (opeType == 2) {
            item.isCollect = !item.isCollect;
            item.up =
              parseInt(item.up || 0) +
              (item.isCollect ? 1 : item.up == 0 ? 0 : -1);
          } else {
            item.isStar = !item.isStar;
            item.star =
              parseInt(item.star || 0) +
              (item.isStar ? 1 : item.star == 0 ? 0 : -1);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index-card {
  width: 100%;
  .tip-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7f7f7f;
    margin: 50px auto;
  }
  &-list {
    width: 100%;
    .list-item {
      width: 100%;
      height: 312px;
      margin-bottom: 48px;
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      transition: all 0.5s;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      .item-img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
      .item-main {
        position: absolute;
        width: 504px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #dfe9f7;
        padding: 32px 24px 14px 29px;
        .main-title {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 14px;
          .title-content {
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            color: #5c9df8;
            &:hover {
              color: #117aff;
            }
          }
          .title-num {
            font-size: 14px;
            font-weight: bold;
            line-height: 19px;
            color: #5c9df8;
          }
        }
        .main-desc {
          height: 36px;
          font-size: 12px;
          color: #5c9df8;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-break: break-all;
          /*！autoprefixer: off */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /*！autoprefixer: on */
        }
        .main-tags {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          height: 59px;
          overflow: hidden;
          margin: 10px 0 5px;
          .tags-item {
            padding: 1px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            background-color: #fff;
            border-radius: 304px;
            margin-right: 10px;
            margin-bottom:5px;
            font-size: 12px;
            line-height: 16px;
            color: #5c9df8;
          }
        }
        .main-buttons {
          display: flex;
          margin-bottom: 25px;
          .buttons-item {
            cursor: pointer;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            width: 145px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: bold;
            transition: all 0.3s;
          }
          .buttons-item:first-child {
            background-color: #5c9df8;
            border-top-left-radius: 188px;
            border-bottom-left-radius: 188px;
            &:hover {
              background-color: #117aff;
            }
          }
          .buttons-item:last-child {
            background-color: #f6bd76;
            border-top-right-radius: 188px;
            border-bottom-right-radius: 188px;
            &:hover {
              background-color: #ff9818;
            }
          }
        }
        .main-handle {
          height: 48px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          border-top: 1px solid #fff;
          .handle-link::v-deep {
            display: flex;
            align-items: center;
            &:hover {
              .link-name {
                color: #117aff;
              }
            }
            .link-avatar {
              width: 18px;
              height: 18px;
              margin-right: 22px;
            }
            .link-name {
              font-size: 14px;
              color: #5c9df8;
            }
          }
          .handle-content {
            display: flex;
            align-items: center;
            .content-item {
              display: flex;
              align-items: center;
              margin-right: 32px;
              color: #5c9df8;
              .item-icon {
                font-size: 18px;
                margin-right: 10px;
              }
              .item-num {
                font-size: 14px;
              }
            }
            .content-item:nth-child(1),
            .content-item:nth-child(2) {
              .item-icon {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .list-item:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      .item-main {
        background-image: linear-gradient(-45deg, #8dc0ff 0%, #dfe9f7 100%);
      }
    }
  }
}
</style>