<template>
  <div class="switch-tab">
    <div
      class="tab-item"
      :class="tabValue === item.value ? 'tab-item-active' : ''"
      v-for="item in tabList"
      :key="item.value"
      @click="tabClick(item.value)"
    >
      {{ item.label }}
    </div>
    <div
      class="tab-background-bar"
      :class="
        tabValue === tabList[0].value
          ? 'tab-background-bar-left'
          : 'tab-background-bar-right'
      "
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    tabValue: { default: true },
    tabList: {
      default() {
        return [];
      },
    },
  },
  methods: {
    tabClick(value) {
      this.$emit('tabClick', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.switch-tab {
  width: 120px;
  height: 30px;
  background-color: #eff3f8;
  position: relative;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  .tab-item {
    position: relative;
    z-index: 999;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #5c9df8;
  }
  .tab-item-active {
    color: #fff;
  }
  .tab-background-bar {
    position: absolute;
    width: 60px;
    height: 28px;
    background-color: #5c9df8;
    top: 1px;
    transition: all 0.3s;
    left: 0;
  }
  .tab-background-bar-left {
    left: 0;
  }
  .tab-background-bar-right {
    left: 60px;
  }
}
</style>